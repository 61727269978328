module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Decker Metal Industrie","short_name":"DeckerSA","description":"The Decker will sich als führendes und unabhängiges Schweizer Dienstleistungsunternehmen in den Bereichen Bau, Metall und Maschinenbau positionieren","lang":"de","display":"standalone","icon":"src/images/icon.png","cache_busting_mode":"none","start_url":"/","background_color":"#f8fafc","theme_color":"#0f172a","localize":[{"start_url":"/en/","lang":"en","name":"Decker Metal Industry","short_name":"DeckerSA","description":"The Decker wants to position itself as a leading and independent Swiss service company in the areas of construction, metal and mechanical engineering"},{"start_url":"/fr/","lang":"fr","name":"Decker Métallurgie","short_name":"DeckerSA","description":"The Decker veut se positionner comme une entreprise de services suisse leader et indépendante dans les domaines de la construction, de la métallurgie et de la construction mécanique"}],"legacy":true,"theme_color_in_head":true,"crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"workboxConfig":{"importWorkboxFrom":"local","globDirectory":"/home/alemd91/GitHub/decker-sa-main","globPatterns":["**/src/images/*"],"dontCacheBustURLsMatching":{},"runtimeCaching":[{"urlPattern":{},"handler":"CacheFirst"},{"urlPattern":{},"handler":"StaleWhileRevalidate"},{"urlPattern":{},"handler":"StaleWhileRevalidate"},{"urlPattern":{},"handler":"StaleWhileRevalidate"}],"skipWaiting":true,"clientsClaim":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[],"titleTemplate":"%s | Decker Metal Industrie","openGraph":{"type":"website","locale":"de","url":"https://decker-sa.ch","site_name":"Decker Metal Industrie"},"twitter":{"handle":"@AlemDervisagic","site":"@AlemDervisagic","cardType":"summary_large_image"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["de","en","fr"],"defaultLanguage":"de","siteUrl":"https://decker-sa.ch","redirect":true,"i18nextOptions":{"fallbackLng":"en","preload":["de","en","fr"],"lowerCaseLng":true,"interpolation":{"escapeValue":false},"keySeparator":false,"nsSeparator":false,"react":{"bindI18n":"languageChanged","useSuspense":true}},"pages":[{"matchPath":"/:lang?/404","getLanguageFromPath":true}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#b91c1c","showSpinner":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-T4VPBYVXWG"],"gtagConfig":{"anonymize_ip":true,"cookie_expires":0},"pluginConfig":{"head":false,"respectDNT":true,"delayOnRouteUpdate":0,"exclude":[],"origin":"https://www.googletagmanager.com"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-apollo/gatsby-browser.js'),
      options: {"plugins":[],"uri":"https://admin.decker-sa.ch/graphql"},
    },{
      plugin: require('../gatsby-browser.jsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
