import '@fontsource/montserrat'

import Layout from './wrapPageElement'

import './src/styles/global.css'
import 'react-loading-skeleton/dist/skeleton.css'

export const wrapPageElement = Layout

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  // transition duration from `layout.js` * 1000 to get time in ms
  // * 2 for exit + enter animation
  const TRANSITION_DELAY = 0.3 * 1000 * 2
  // if it's a "normal" route
  const jobsPosition = getSavedScrollPosition({
    pathname: `/decker/jobs-and-career/#job-form`,
  })
  if (jobsPosition) {
    window.scrollTo(...jobsPosition)
  }
  if (location.action === 'PUSH') {
    window.setTimeout(() => window.scrollTo(0, 0), TRANSITION_DELAY)
  }
  // if we used the browser's forwards or back button
  else {
    const savedPosition = getSavedScrollPosition(location) || [0, 0]
    window.setTimeout(() => window.scrollTo(...savedPosition), TRANSITION_DELAY)
  }
  return false
}
