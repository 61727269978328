import { gql } from '@apollo/client'

export const jobFragment = gql`
  fragment JobFragment on Job {
    dateFrom
    dateTo
    featuredImage {
      node {
        mediaItemUrl
        sizes(size: _1536X1536)
        srcSet(size: _1536X1536)
        mediaDetails {
          width
          height
        }
      }
    }
    id
    content: jobContent
    intro: jobIntro
    mainTitle
    secondTitle
    slug
    title
  }
`

export const newsFragment = gql`
  fragment NewsFragment on News {
    content
    createdDate
    featuredImage {
      node {
        mediaItemUrl
        sizes(size: _1536X1536)
        srcSet(size: _1536X1536)
        mediaDetails {
          width
          height
        }
      }
    }
    id
    title
    slug
    intro
  }
`
