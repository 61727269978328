'use client'
import { useEffect } from 'react'
import clsx from 'clsx'
import { motion, useScroll, useSpring } from 'framer-motion'

import useBrowser from '../../hooks/useBrowser'
import { useAppState } from '../../store/main'

const BackToTop = () => {
  const { isScrolledBtn, setisScrolledBtn } = useAppState()
  const { scrollYProgress } = useScroll()
  useEffect(() => {
    function onScroll() {
      setisScrolledBtn(window.scrollY > 400)
    }

    onScroll()
    window.addEventListener('scroll', onScroll)
    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  }, [setisScrolledBtn])

  const isBrowser = useBrowser()

  const goToTop = () => {
    if (isBrowser) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    }
  }
  const pathL = useSpring(scrollYProgress, {
    stiffness: 100,
    damping: 30,
    restDelta: 0.001,
  })
  return (
    <motion.button
      whileHover={{ y: -10, transition: { duration: 0.5 } }}
      type="button"
      onClick={() => goToTop()}
      className={clsx({
        'fixed bottom-10 right-6 z-50 bg-transparent': isScrolledBtn,
        hidden: !isScrolledBtn,
      })}
    >
      <svg
        id="progress"
        width="75"
        height="75"
        viewBox="0 0 100 100"
        className="fill-transparent stroke-2 hover:fill-red-600/50"
      >
        <circle
          cx="50"
          cy="50"
          r="30"
          fill="none"
          pathLength="1"
          className="stroke-slate-900 dark:stroke-slate-100"
        ></circle>

        <motion.circle
          cx="50"
          cy="50"
          r="30"
          pathLength="1"
          className="stroke-red-600"
          style={{ pathLength: pathL }}
        ></motion.circle>

        <motion.path
          className="fill-slate-900 stroke-1 dark:fill-slate-100"
          style={{ translateX: 26, translateY: 18 }}
          fill=""
          d="M42 38L39 41 24 26.147 9 41 6 38 24 20z"
        />
      </svg>
    </motion.button>
  )
}

export default BackToTop
