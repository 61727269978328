'use client'
import { AnimatePresence, motion } from 'framer-motion'

const variants = {
  hidden: { opacity: 0 },
  enter: {
    opacity: 1,
    transition: {
      duration: 0.5,
      delay: 0.5,
      when: 'beforeChildren',
    },
  },
  exit: { opacity: 0, transition: { duration: 0.7 } },
}

const PageTransition = ({ children, pathname }) => {
  return (
    <AnimatePresence mode={'wait'}>
      <motion.main
        key={pathname}
        className="h-full w-full"
        variants={variants}
        initial="hidden"
        animate="enter"
        exit="exit"
      >
        {children}
      </motion.main>
    </AnimatePresence>
  )
}
export default PageTransition
