exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-500-js": () => import("./../../../src/pages/500.js" /* webpackChunkName: "component---src-pages-500-js" */),
  "component---src-pages-business-mechanical-engineering-js": () => import("./../../../src/pages/business/mechanical-engineering.js" /* webpackChunkName: "component---src-pages-business-mechanical-engineering-js" */),
  "component---src-pages-business-metal-construction-industry-js": () => import("./../../../src/pages/business/metal-construction-industry.js" /* webpackChunkName: "component---src-pages-business-metal-construction-industry-js" */),
  "component---src-pages-business-our-products-js": () => import("./../../../src/pages/business/our-products.js" /* webpackChunkName: "component---src-pages-business-our-products-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-decker-jobs-and-career-js": () => import("./../../../src/pages/decker/jobs-and-career.js" /* webpackChunkName: "component---src-pages-decker-jobs-and-career-js" */),
  "component---src-pages-decker-our-story-js": () => import("./../../../src/pages/decker/our-story.js" /* webpackChunkName: "component---src-pages-decker-our-story-js" */),
  "component---src-pages-decker-partners-js": () => import("./../../../src/pages/decker/partners.js" /* webpackChunkName: "component---src-pages-decker-partners-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-privacy-js": () => import("./../../../src/pages/legal/privacy.js" /* webpackChunkName: "component---src-pages-legal-privacy-js" */),
  "component---src-pages-legal-terms-js": () => import("./../../../src/pages/legal/terms.js" /* webpackChunkName: "component---src-pages-legal-terms-js" */),
  "component---src-pages-news-index-js": () => import("./../../../src/pages/news/index.js" /* webpackChunkName: "component---src-pages-news-index-js" */),
  "component---src-pages-projects-realization-js": () => import("./../../../src/pages/projects-realization.js" /* webpackChunkName: "component---src-pages-projects-realization-js" */),
  "component---src-templates-mechanical-engineering-jsx-content-file-path-content-business-mech-assembly-assembly-mdx": () => import("./../../../src/templates/mechanical_engineering.jsx?__contentFilePath=/home/alemd91/GitHub/decker-sa-main/content/business/mech/assembly/assembly.mdx" /* webpackChunkName: "component---src-templates-mechanical-engineering-jsx-content-file-path-content-business-mech-assembly-assembly-mdx" */),
  "component---src-templates-mechanical-engineering-jsx-content-file-path-content-business-mech-machining-machining-mdx": () => import("./../../../src/templates/mechanical_engineering.jsx?__contentFilePath=/home/alemd91/GitHub/decker-sa-main/content/business/mech/machining/machining.mdx" /* webpackChunkName: "component---src-templates-mechanical-engineering-jsx-content-file-path-content-business-mech-machining-machining-mdx" */),
  "component---src-templates-mechanical-engineering-jsx-content-file-path-content-business-mech-parts-sheet-metal-mdx": () => import("./../../../src/templates/mechanical_engineering.jsx?__contentFilePath=/home/alemd91/GitHub/decker-sa-main/content/business/mech/parts/sheet-metal.mdx" /* webpackChunkName: "component---src-templates-mechanical-engineering-jsx-content-file-path-content-business-mech-parts-sheet-metal-mdx" */),
  "component---src-templates-metal-constructions-jsx-content-file-path-content-business-metal-balcony-balcony-mdx": () => import("./../../../src/templates/metal_constructions.jsx?__contentFilePath=/home/alemd91/GitHub/decker-sa-main/content/business/metal/balcony/balcony.mdx" /* webpackChunkName: "component---src-templates-metal-constructions-jsx-content-file-path-content-business-metal-balcony-balcony-mdx" */),
  "component---src-templates-metal-constructions-jsx-content-file-path-content-business-metal-doors-doors-mdx": () => import("./../../../src/templates/metal_constructions.jsx?__contentFilePath=/home/alemd91/GitHub/decker-sa-main/content/business/metal/doors/doors.mdx" /* webpackChunkName: "component---src-templates-metal-constructions-jsx-content-file-path-content-business-metal-doors-doors-mdx" */),
  "component---src-templates-metal-constructions-jsx-content-file-path-content-business-metal-facades-facades-mdx": () => import("./../../../src/templates/metal_constructions.jsx?__contentFilePath=/home/alemd91/GitHub/decker-sa-main/content/business/metal/facades/facades.mdx" /* webpackChunkName: "component---src-templates-metal-constructions-jsx-content-file-path-content-business-metal-facades-facades-mdx" */),
  "component---src-templates-metal-constructions-jsx-content-file-path-content-business-metal-fence-fence-mdx": () => import("./../../../src/templates/metal_constructions.jsx?__contentFilePath=/home/alemd91/GitHub/decker-sa-main/content/business/metal/fence/fence.mdx" /* webpackChunkName: "component---src-templates-metal-constructions-jsx-content-file-path-content-business-metal-fence-fence-mdx" */),
  "component---src-templates-metal-constructions-jsx-content-file-path-content-business-metal-fire-fire-mdx": () => import("./../../../src/templates/metal_constructions.jsx?__contentFilePath=/home/alemd91/GitHub/decker-sa-main/content/business/metal/fire/fire.mdx" /* webpackChunkName: "component---src-templates-metal-constructions-jsx-content-file-path-content-business-metal-fire-fire-mdx" */),
  "component---src-templates-metal-constructions-jsx-content-file-path-content-business-metal-metal-metal-mdx": () => import("./../../../src/templates/metal_constructions.jsx?__contentFilePath=/home/alemd91/GitHub/decker-sa-main/content/business/metal/metal/metal.mdx" /* webpackChunkName: "component---src-templates-metal-constructions-jsx-content-file-path-content-business-metal-metal-metal-mdx" */),
  "component---src-templates-metal-constructions-jsx-content-file-path-content-business-metal-stairs-stairs-mdx": () => import("./../../../src/templates/metal_constructions.jsx?__contentFilePath=/home/alemd91/GitHub/decker-sa-main/content/business/metal/stairs/stairs.mdx" /* webpackChunkName: "component---src-templates-metal-constructions-jsx-content-file-path-content-business-metal-stairs-stairs-mdx" */),
  "component---src-templates-metal-constructions-jsx-content-file-path-content-business-metal-windows-windows-mdx": () => import("./../../../src/templates/metal_constructions.jsx?__contentFilePath=/home/alemd91/GitHub/decker-sa-main/content/business/metal/windows/windows.mdx" /* webpackChunkName: "component---src-templates-metal-constructions-jsx-content-file-path-content-business-metal-windows-windows-mdx" */),
  "component---src-templates-services-template-jsx-content-file-path-content-services-consultation-consultation-mdx": () => import("./../../../src/templates/services_template.jsx?__contentFilePath=/home/alemd91/GitHub/decker-sa-main/content/services/consultation/consultation.mdx" /* webpackChunkName: "component---src-templates-services-template-jsx-content-file-path-content-services-consultation-consultation-mdx" */),
  "component---src-templates-services-template-jsx-content-file-path-content-services-development-development-mdx": () => import("./../../../src/templates/services_template.jsx?__contentFilePath=/home/alemd91/GitHub/decker-sa-main/content/services/development/development.mdx" /* webpackChunkName: "component---src-templates-services-template-jsx-content-file-path-content-services-development-development-mdx" */),
  "component---src-templates-services-template-jsx-content-file-path-content-services-montage-montage-mdx": () => import("./../../../src/templates/services_template.jsx?__contentFilePath=/home/alemd91/GitHub/decker-sa-main/content/services/montage/montage.mdx" /* webpackChunkName: "component---src-templates-services-template-jsx-content-file-path-content-services-montage-montage-mdx" */),
  "component---src-templates-services-template-jsx-content-file-path-content-services-production-production-mdx": () => import("./../../../src/templates/services_template.jsx?__contentFilePath=/home/alemd91/GitHub/decker-sa-main/content/services/production/production.mdx" /* webpackChunkName: "component---src-templates-services-template-jsx-content-file-path-content-services-production-production-mdx" */),
  "component---src-templates-services-template-jsx-content-file-path-content-services-project-management-project-management-mdx": () => import("./../../../src/templates/services_template.jsx?__contentFilePath=/home/alemd91/GitHub/decker-sa-main/content/services/project_management/project-management.mdx" /* webpackChunkName: "component---src-templates-services-template-jsx-content-file-path-content-services-project-management-project-management-mdx" */),
  "component---src-templates-services-template-jsx-content-file-path-content-services-supply-chain-supply-mdx": () => import("./../../../src/templates/services_template.jsx?__contentFilePath=/home/alemd91/GitHub/decker-sa-main/content/services/supply_chain/supply.mdx" /* webpackChunkName: "component---src-templates-services-template-jsx-content-file-path-content-services-supply-chain-supply-mdx" */)
}

