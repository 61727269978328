import { createWithEqualityFn } from 'zustand/traditional'

import { wrap } from '../lib/wrap'

const initialState = {
  isScrolled: false,
  isScrolledBtn: false,
}
const createThemeSlice = (set) => ({
  theme: null,
  setThemeColor: (theme) => set(() => ({ theme: theme })),
})
const createArticleSlice = (set) => ({
  article: 'cheffe-de-projet-en-construction-metallique',
  setArticle: (slug) => set(() => ({ article: slug })),
})
const createMapSlice = (set) => ({
  map: null,
  isLoading: false,
  zoom: 10,
  center: { lat: 47.03348799059861, lng: 7.013397477091063 },
  markerList: [
    { lat: 47.36094944679884, lng: 8.092202230681504 },
    { lat: 47.03348799059861, lng: 7.013397477091063 },
    { lat: 46.563917740507826, lng: 6.56641225532354 },
  ],
  setZoom: (zoom) => set(() => ({ zoom: zoom })),
  setCenter: (center) => set(() => ({ center: center })),
  setMap: (map) => set(() => ({ map: map })),
  setLoaded: (loaded) => set(() => ({ isLoading: loaded })),
})

const createMediaSlice = (set) => ({
  windowWidth: typeof window !== `undefined` ? window.innerWidth : null,
  isDesiredWidth: false,
  setMedia: ({ windowWidth, isDesiredWidth }) =>
    set(() => ({ windowWidth: windowWidth, isDesiredWidth: isDesiredWidth })),
})

const createMobileMenuSlice = (set) => ({
  mobileOpen: false,
  dropDownOpen: false,
  openMobileMenu: () => set((state) => ({ mobileOpen: !state.mobileOpen })),
  openDropDown: () => set((state) => ({ dropDownOpen: !state.dropDownOpen })),
})
const createMenuSlice = (set) => ({
  isMenuOpen: null,
  openMenu: (id) => set(() => ({ isMenuOpen: id })),
  toggleMenu: () => set((state) => ({ isMenuOpen: !state.isMenuOpen })),
  closeMenu: () => set(() => ({ isMenuOpen: null })),
})
const createModalSlice = (set) => ({
  modalOpen: false,
  selectedIndex: 0,
  setModalOpen: () => set((state) => ({ modalOpen: !state.modalOpen })),
  setSelectedIndex: (state) => set({ selectedIndex: state }),
})

const createMainSlice = (set) => ({
  ...initialState,
  setIsScrolled: (state) => set({ isScrolled: state }),
  setisScrolledBtn: (state) => set({ isScrolledBtn: state }),
})

const createLoopLogoSlice = (set) => ({
  logoIndex: 0,
  setLogoIndex: (direction, max) =>
    set((state) => ({
      logoIndex: wrap(0, max, state.logoIndex + direction),
    })),
})
const createNewsSlice = (set) => ({
  news: [],
  setNews: (news) => set(() => ({ news: news })),
})
const createJobsSlice = (set) => ({
  job: 'cheffe-de-projet-en-construction-metallique',
  setJob: (job) => set(() => ({ job })),
})
const createProjectsSlice = (set) => ({
  projects: [],
  loadingProjects: true,
  setProjects: (projects) => set({ projects: projects }),
})
const createServicesSlice = (set) => ({
  services: [],
  setServices: (services) => set(() => ({ services: services })),
})
const createLanguageSlice = (set) => ({
  i18n: {},
  lngLoaded: false,
  setI18n: (i18n) => set(() => ({ i18n })),
  setLngLoaded: (action) => set(() => ({ lngLoaded: action })),
})
const useAppState = createWithEqualityFn((...a) => ({
  ...createMobileMenuSlice(...a),
  ...createMenuSlice(...a),
  ...createModalSlice(...a),
  ...createMainSlice(...a),
  ...createNewsSlice(...a),
  ...createLoopLogoSlice(...a),
  ...createMediaSlice(...a),
  ...createMapSlice(...a),
  ...createServicesSlice(...a),
  ...createJobsSlice(...a),
  ...createThemeSlice(...a),
  ...createProjectsSlice(...a),
  ...createArticleSlice(...a),
  ...createLanguageSlice(...a),
}))

export { useAppState }
