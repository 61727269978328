import { BackToTop } from './Index'
import PageTransition from './PageTransition'

const Layout = ({ children, location }) => {
  return (
    <section>
      <PageTransition pathname={location.pathname}>
        {children}
        <BackToTop />
      </PageTransition>
    </section>
  )
}

export default Layout
