import { I18nProvider } from 'react-aria'

import { Layout } from './src/components/Theme/Index'

const wrapPageElement = ({ element, props }) => {
  return (
    <I18nProvider locale={props.pageContext.language}>
      <Layout {...props}>{element}</Layout>
    </I18nProvider>
  )
}

export default wrapPageElement
