import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client'
import { createFragmentRegistry } from '@apollo/client/cache'
import fetch from 'isomorphic-unfetch'

import { jobFragment, newsFragment } from '../lib/fragments'

const cache = new InMemoryCache({
  fragments: createFragmentRegistry(jobFragment, newsFragment),
})

const client = new ApolloClient({
  ssrMode: true,
  cache: cache,
  link: createHttpLink({
    uri: 'https://admin.decker-sa.ch/graphql',
    fetch,
  }),
})

export default client
